import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fontsource/roboto/100.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

export default new Vuetify({
});
