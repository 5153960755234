<template>
  <v-app>
    <router-view name="nav"></router-view>
    <router-view name="headerbar"></router-view>
    <router-view></router-view>
    <router-view name="footer"></router-view>

  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},

  data () {
    return {};
  },
  created () { },
};
</script>
<style>
::-webkit-scrollbar {
  /*隐藏滾輪*/
  display: none;
}
</style>