<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 style="font-size: 20pt; color: #000000">用戶資訊</h3>
          <v-spacer />
          <v-col cols="12" sm="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          show-expand
          :single-expand="true"
          @item-expanded="Expand"
          :expanded="expanded"
          :headers="headers"
          :items="desserts"
          :search="search"
          :footer-props="{
            'items-per-page-options': [20, 40, -1],
          }"
          class="elevation-1"
        >
          <template v-slot:[`item.enable`]="{ item }">
            <div v-if="item.enable">
              <v-icon color="blue">mdi-check-circle</v-icon>
            </div>
          </template>
          <template v-slot:[`item.update`]="{ item }">
            <v-btn
              depressed
              dense
              class="mr-7 green accent-7 white--text"
              @click="editItem(item)"
            >
              Edit
            </v-btn>
            <v-btn
              depressed
              dense
              class="mr-7 pink lighten-2 white--text"
              @click="deleteItem(item)"
            >
              Delete
            </v-btn>
            <v-btn
              depressed
              dense
              class="mr-7 blue lighten-2 white--text"
              @click="openGatewayDialog(item)"
            >
              Add Gateway
            </v-btn>
            <v-btn
              depressed
              dense
              class="mr-7 blue lighten-2 white--text"
              @click="openShareDialog(item)"
            >
              Add Share
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length">
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      <h3>Gateway列表</h3>
                      <v-spacer />
                      <v-col cols="12" sm="5">
                        <v-text-field
                          v-model="gatewaySearch"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-card-title>
                    <v-data-table
                      :headers="gatewayHeaders"
                      :items="gatewayDesserts"
                      :search="gatewaySearch"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.share`]="{ item }">
                        <v-icon class="mr-3" @click="removeGateway(item)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card>
                    <v-card-title>
                      <h3>被分享Gateway</h3>
                      <v-spacer />
                      <v-col cols="12" sm="5">
                        <v-text-field
                          v-model="shareSearch"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-card-title>
                    <v-data-table
                      :headers="shareHeaders"
                      :items="shareDesserts"
                      :search="shareSearch"
                      class="elevation-1"
                    >
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <!-- --------------------------------Update--------------------------------- -->
    <v-dialog v-model="updateDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">帳戶內容</v-card-title>
        <v-card-text class="subtitle-1 font-weight-bold">
          <v-container>
            <v-row>
              <v-text-field
                v-model="editedItem.email"
                type="text"
                label="Email"
                readonly
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                v-model="editedItem.password"
                :type="showPassword ? 'text' : 'password'"
                append-icon="mdi-eye-off"
                label="Password"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-radio-group v-model="editedItem.enable" row mandatory>
                <v-col>
                  <span>是否開通</span>
                </v-col>
                <v-col>
                  <v-radio label="是" :value="true"></v-radio>
                </v-col>
                <v-col>
                  <v-radio label="否" :value="false"></v-radio>
                </v-col>
              </v-radio-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="updateDialog = false">取消</v-btn>
          <v-btn color="green darken-1" text @click="UpdateUser()">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- -------------------------------------Delete------------------------------------------- -->
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="headline">確定要刪除?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">取消</v-btn>
          <v-btn color="green darken-1" text @click="DeleteUser()">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- --------------------------------------Gateway------------------------------------------------ -->
    <v-dialog v-model="gatewayDialog" max-width="700">
      <v-card>
        <v-card-title>
          <h3>ADD Gateway</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field v-model="gwsn" label="SN"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="gwname" label="Name"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text" text @click="closeGatewayDialog()">Cancel</v-btn>
          <v-btn class="blue--text" text @click="addGateway()">ADD</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ---------------------------------------Share------------------------------------------------- -->
    <v-dialog v-model="shareDialog" max-width="700">
      <v-card>
        <v-card-title>
          <h3>ADD Share</h3>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="5">
              <v-text-field v-model="shareSN" label="SN"></v-text-field>
            </v-col>
            <v-col>
              <v-text-field v-model="shareEmail" label="Email"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text" text @click="closeShareDialog()">Cancel</v-btn>
          <v-btn class="blue--text" text @click="addShare()">ADD</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      showPassword: false,
      updateDialog: false,
      deleteDialog: false,
      expanded: [],
      search: "",
      headers: [
        {
          text: "帳戶",
          value: "email",
          width: 150,
        },
        {
          text: "是否開通",
          value: "enable",
          sortable: false,
        },
        {
          text: "修改&刪除",
          value: "update",
          sortable: false,
          align: "center",
          // width: 600,
        },
        { text: "", value: "data-table-expand" },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {},
      user: "",
      // ====================================add gateway================================================
      gatewayDialog: false,
      gatewaySearch: "",
      gatewayHeaders: [
        {
          text: "sn",
          value: "sn",
          align: "center",
        },
        { text: "名稱", value: "nickname", align: "center" },
        { text: "", value: "share", align: "center", sortable: false },
      ],
      gatewayDesserts: [],
      gatewayItem: {},
      gwsn: null,
      gwname: null,
      // ===================================add share==================================================
      shareDialog: false,
      shareSearch: "",
      shareHeaders: [
        {
          text: "sn",
          value: "sn",
          align: "center",
        },
        { text: "e-mail", value: "email", align: "center" },
      ],
      shareDesserts: [],
      shareItem: {},
      shareSN: null,
      shareEmail: null,
    };
  },
  created() {
    this.init();
  },
  watch: {
    gatewayDialog(val) {
      val || this.closeGatewayDialog();
    },
    shareDialog(val) {
      val || this.closeShareDialog();
    },
  },
  methods: {
    init() {
      axios.get("/api/getuser").then((res) => {
        this.desserts = res.data.data;
      });
    },
    Expand(item) {
      // console.log(item);
      this.user = item.item.email;
      this.gatewayDesserts = [];
      this.shareDesserts = [];
      if (item.value) {
        axios.get("/api/gateway/" + item.item.token).then((res) => {
          this.gatewayDesserts = res.data.data;
        });
        axios.get("/api/gw/share/" + item.item.token).then((res) => {
          var resData = res.data.data;
          // this.shareDesserts = res.data.data;
          for (let i in resData) {
            resData[i].email = "";
            axios.get("/api/user/q/" + resData[i].token).then((res) => {
              resData[i].email = res.data;
              this.shareDesserts = resData;
            });
          }
        });
      }
    },
    // =======================edit====================================
    editItem(item) {
      this.updateDialog = true;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    UpdateUser() {
      // console.log(this.editedItem, typeof this.editedItem.enable);
      var url = "/api/user/" + this.editedItem.token;
      axios.patch(url, this.editedItem, {}).then(() => {
        Swal.fire("已更新", "", "success");
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
        this.updateDialog = false;
      });
    },
    // ======================delete======================================
    deleteItem(item) {
      this.deleteDialog = true;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    DeleteUser() {
      var url = "/api/user/" + this.editedItem.token;
      axios.delete(url, {}).then(() => {
        Swal.fire("已刪除", "", "success");
        this.desserts.splice(this.editedIndex, 1);
        this.deleteDialog = false;
      });
    },
    // ======================gateway==================================
    removeGateway(item) {
      var url = "/api/gateway/" + item.token + "/" + item.sn;
      axios.delete(url).then(() => {
        for (let i in this.gatewayDesserts) {
          if (this.gatewayDesserts[i].sn == item.sn) {
            this.gatewayDesserts.splice(i, 1);
          }
        }
        Swal.fire("已刪除", "", "success");
      });
    },
    addGateway() {
      var Query = {
        token: this.gatewayItem.token,
        sn: this.gwsn,
        nickname: this.gwname,
      };
      axios.post("/api/gateway", Query).then(() => {
        Swal.fire("新增成功", "", "success");
        this.gatewayDialog = false;
      });
    },
    // =========================share===================================
    addShare() {
      var myreg = /\S+@\S+.\S+/;
      var url = "/api/gw/share/" + this.shareItem.token + "/" + this.shareSN;
      var Query = {
        sn: this.shareSN,
        email: this.shareEmail,
        edit: false,
      };
      if (myreg.test(this.shareEmail)) {
        axios.post(url, Query).then(() => {
          Swal.fire("新增成功", "", "success");
          this.shareDialog = false;
        });
      } else {
        Swal.fire("Email格式錯誤", "", "error");
      }
    },
    // ==========================Dialog State=======================================
    openGatewayDialog(item) {
      this.gatewayDialog = true;
      this.gatewayItem = item;
    },
    closeGatewayDialog() {
      this.gatewayDialog = false;
      this.gwsn = null;
      this.gwname = null;
    },
    openShareDialog(item) {
      this.shareDialog = true;
      this.shareItem = item;
    },
    closeShareDialog() {
      this.shareDialog = false;
      this.shareSN = null;
      this.shareEmail = null;
    },
  },
};
</script>
<style>
.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
