import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import i18n from "./i18n";
//import axios from "axios";
//import VueAxios from "vue-axios";

//import "chart.js";
//import "hchs-vue-charts";
//import Chartkick from "chartkick";
//import VueChartkick from "vue-chartkick";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

//初始化
// Vue.use(window.VueCharts);
// Vue.use(VueChartkick, {
//   Chartkick
// });
Vue.use(VueSweetalert2, options);

//Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

//通訊 bus event
export const bus = new Vue();

router.beforeEach((to, from, next) => {
  //const isLogin = localStorage.getItem("token") == "";
  const isLogin = localStorage.getItem("token");

  var permission = localStorage.getItem('permission');
  if (isLogin) {//登陸成功
    if (to.meta.authorize.includes(permission)) {//判斷這頁面的權限
      next()
    } else {
      //沒有權限倒頁到-> /
      return next({ path: '/' })
    }
  } else { //登陸失敗
    if (to.path !== "/login") {
      next("/login");
    }
    else {
      next()
    }
  }
});

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
