<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3 style="font-size: 20pt; color: #000000">SN</h3>
          <v-row>
            <v-spacer />
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :search="search"
          :items="desserts"
          :footer-props="{
            'items-per-page-options': [20, 40, -1],
          }"
          class="elevation-1"
        >
          <template v-slot:[`item.update`]="{ item }">
            <!-- <v-btn
              depressed
              dense
              class="mr-7 blue accent-7 white--text"
              @click="getShareItem(item)"
            >
              Share
            </v-btn> -->
            <v-icon class="mr-3 green--text" @click="getShareItem(item)">
              mdi-share-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <v-dialog v-model="shareDialog" max-width="500">
      <v-data-table :headers="shareHeaders" :items="shareDesserts" class="elevation-1">
      </v-data-table>
    </v-dialog>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      shareDialog: false,
      search: "",
      headers: [
        {
          text: "Gateway",
          value: "sn",
          align: "center",
        },
        {
          text: "Name",
          value: "nickname",
          align: "center",
        },
        {
          text: "Email",
          value: "email",
          align: "center",
        },
        {
          text: "Create Time",
          value: "ctime",
          align: "center",
        },
        {
          text: "被分享序號",
          value: "update",
          align: "center",
        },
      ],
      desserts: [],
      // ==============
      shareHeaders: [
        {
          text: "SN",
          value: "sn",
          align: "center",
        },
        {
          text: "email",
          value: "email",
          align: "center",
        },
      ],
      shareDesserts: [],
    };
  },
  created() {
    this.getData();
  },
  watch: {
    shareDialog(val) {
      val || this.Close();
    },
  },
  methods: {
    getData() {
      axios.get("/api/gateways").then((res) => {
        var data = res.data.data;
        // this.desserts = res.data.data;
        axios.get("/api/getuser").then((res) => {
          for (let i in data) {
            for (let j in res.data.data) {
              if (data[i].token == res.data.data[j].token) {
                data[i].email = res.data.data[j].email;
              }
            }
          }
          this.desserts = data;
        });
      });
    },
    getShareItem(item) {
      this.shareDialog = true;
      axios.get("/api/gw/share/" + item.token).then((res) => {
        var data = res.data.data;
        for (let i in data) {
          axios.get("/api/user/q/" + data[i].token).then((res) => {
            data[i].email = res.data;
            this.shareDesserts = data;
          });
        }
      });
    },
    Close() {
      this.shareDialog = false;
      this.shareDesserts = [];
    },
  },
};
</script>
